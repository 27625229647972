var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-template', [_c('v-layout', {
    staticClass: "fill-height justify-center align-start background-content"
  }, [_c('v-responsive', {
    staticClass: "sub-gift--list overflow-visible",
    attrs: {
      "width": "100%",
      "min-height": _vm.$vuetify.breakpoint.mobile ? '83vh' : '50vh',
      "max-width": "1200"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "headline d-flex d-sm-block justify-center"
  }, [-1 < _vm.$route.path.indexOf('/center/notifications') ? _c('b', {
    staticClass: "py-4 pt-0 pl-sm-4"
  }, [_vm._v("공지사항")]) : _vm._e(), -1 < _vm.$route.path.indexOf('/center/faqs') ? _c('b', {
    staticClass: "py-4 pt-0 pl-sm-4"
  }, [_vm._v("자주묻는질문")]) : _vm._e(), -1 < _vm.$route.path.indexOf('/board/question') ? _c('b', {
    staticClass: "py-4 pt-0 pl-sm-4"
  }, [_vm._v("문의하기")]) : _vm._e()]), _c('v-card-text', [_c('div', {
    staticClass: "center-banner-top"
  }, [_c('h4', [_c('span', [_vm._v("기프티콘스")]), _vm._v(" 쇼핑몰 "), _c('span', [_vm._v("문의상담")]), _vm._v("은")]), _c('p', [_vm._v("아래 게시판에 문의주시면 빠른 답변드리겠습니다.")])]), _c('div', {
    staticClass: "center-banner-bottom"
  }, [_c('ul', [_c('li', {
    staticClass: "banner-bottom__red"
  }, [_c('p', [_vm._v("대표번호")]), _c('h5', [_vm._v("1551-0230")])]), _c('li', {
    staticClass: "banner-bottom__yellow"
  }, [_c('p', [_vm._v("대표핸드폰번호")]), _c('h5', [_vm._v("010-6569-3722")])]), _c('li', {
    staticClass: "banner-bottom__red"
  }, [_c('v-btn', {
    attrs: {
      "href": 'https://2dk6e.channel.io/',
      "target": "_blank"
    }
  }, [_c('h6', [_vm._v("카톡 상담")])])], 1)])])]), _c('v-divider', {
    staticClass: "mt-4"
  })], 1), _vm._t("default")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }